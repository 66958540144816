:root {
  --shadow-color: 214deg 100% 20%;
  --shadow-color-2: 194deg 100% 16%;
  --shadow-elevation-low: 0px 0.7px 0.9px hsl(var(--shadow-color) / 0.22),
    0px 1.4px 1.8px -0.7px hsl(var(--shadow-color) / 0.34),
    0px 3.2px 4.1px -1.4px hsl(var(--shadow-color) / 0.46);
  --shadow-elevation-medium: 0px 0.7px 0.9px hsl(var(--shadow-color) / 0.23),
    0px 3.1px 4px -0.5px hsl(var(--shadow-color) / 0.33),
    -0.1px 7.1px 9.2px -0.9px hsl(var(--shadow-color) / 0.42),
    -0.2px 15.9px 20.5px -1.4px hsl(var(--shadow-color) / 0.51);
  --shadow-elevation-high: 0px 0.7px 0.9px hsl(var(--shadow-color) / 0.22),
    -0.1px 6.6px 8.5px -0.2px hsl(var(--shadow-color) / 0.26),
    -0.2px 12px 15.5px -0.4px hsl(var(--shadow-color) / 0.3),
    -0.2px 18.6px 24px -0.6px hsl(var(--shadow-color) / 0.34),
    -0.4px 27.7px 35.7px -0.8px hsl(var(--shadow-color) / 0.39),
    -0.5px 40.9px 52.8px -1px hsl(var(--shadow-color) / 0.43),
    -0.8px 59.5px 76.8px -1.2px hsl(var(--shadow-color) / 0.47),
    -1.1px 85.3px 110px -1.4px hsl(var(--shadow-color) / 0.51);

  --shadow-elevation-low-2: 0px 0.3px 0.3px hsl(var(--shadow-color-2) / 0.34),
    0px 0.5px 0.6px -1.2px hsl(var(--shadow-color-2) / 0.34),
    0px 1.2px 1.3px -2.5px hsl(var(--shadow-color-2) / 0.34);
  --shadow-elevation-medium-2: 0px 0.3px 0.3px hsl(var(--shadow-color-2) / 0.36),
    0px 1px 1.1px -0.8px hsl(var(--shadow-color-2) / 0.36),
    0.1px 2.5px 2.8px -1.7px hsl(var(--shadow-color-2) / 0.36),
    0.2px 6px 6.8px -2.5px hsl(var(--shadow-color-2) / 0.36),
    0.5px 13.4px 15.1px -1.8px hsl(var(--shadow-color-2) / 0.36);
  --shadow-elevation-high-2: 0px 0.3px 0.3px hsl(var(--shadow-color-2) / 0.34),
    0.1px 1.8px 2px -0.4px hsl(var(--shadow-color-2) / 0.34),
    0.1px 3.3px 3.7px -0.7px hsl(var(--shadow-color-2) / 0.34),
    0.2px 5.4px 6.1px -1.1px hsl(var(--shadow-color-2) / 0.34),
    0.3px 8.6px 9.7px -1.4px hsl(var(--shadow-color-2) / 0.34),
    0.5px 13.4px 15.1px -1.8px hsl(var(--shadow-color-2) / 0.34),
    0.8px 20.4px 23px -2.1px hsl(var(--shadow-color-2) / 0.34),
    1.2px 30.1px 33.9px -2.5px hsl(var(--shadow-color-2) / 0.34);
}

@import url("https://fonts.googleapis.com/css2?family=Silkscreen&display=swap");
@import url("https://fonts.cdnfonts.com/css/procrastinating-pixie");

/* animations */

@keyframes flame-fx {
  from {
    color: rgb(220, 15, 5);
    filter: drop-shadow(-3px -2px 7px yellow);
  }

  to {
    color: rgb(255, 0, 0);
    filter: drop-shadow(2px -2px 9px yellow);
  }
}

@keyframes fire-fx {
  from {
    color: rgb(220, 15, 5);
    filter: drop-shadow(0px 0px 2px yellow);
  }

  to {
    color: rgb(255, 0, 0);
    filter: drop-shadow(0px 3px -2px yellow);
  }
}

@keyframes sun-fx {
  from {
    filter: invert(1%);
    filter: hue-rotate(90deg);
  }

  to {
    filter: invert(100%);
    filter: hue-rotate(270deg);
  }
}

@keyframes glass-fx {
  from {
    filter: drop-shadow(2px 2px 1px gray) blur(5px);
  }

  to {
    filter: drop-shadow(2px 2px 1px gray) blur(0);
  }
}

@keyframes drip-fx {
  from {
    filter: drop-shadow(0px 0px 2px aqua);
  }

  to {
    color: rgb(0, 139, 139);
    filter: drop-shadow(0px 2px 3px blue);
  }
}

@keyframes electric-fx {
  from {
    filter: drop-shadow(0px 0px 1px aqua);
  }

  to {
    color: rgb(33, 50, 74);
    filter: drop-shadow(0px 2px 14px rgb(255, 255, 0));
  }
}

@keyframes water-fx {
  from {
    filter: drop-shadow(0px 0px 2px aqua);
  }

  to {
    color: rgb(0, 139, 139);
    filter: drop-shadow(0px 3px 3px blue);
  }
}

@keyframes breeze-fx {
  from {
    transform: translate(0px);
  }

  25% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(0px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes wind-fx {
  from {
    transform: translate(0px);
    color: rgb(192, 186, 180);
  }

  to {
    color: rgb(0, 139, 139);
    transform: translate(10px);
  }
}

@keyframes dirt-fx {
  from {
    filter: drop-shadow(0px 0px 4px green);
  }

  to {
    filter: drop-shadow(0px 0px 4px green);
  }
}

@keyframes error-fx {
  from {
    color: rgb(220, 15, 5);
  }

  to {
    color: rgb(255, 0, 0);
    filter: drop-shadow(0px 3px -2px yellow);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.puffIn {
  animation-name: puffIn;
  width: 100%;
  height: 100%;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes puffIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    filter: blur(1px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
  }
}

.shake {
  animation: shakeHorizontal 0.5s;
  animation-iteration-count: 1;
}

.reverse-shake {
  animation: shakeHorizontalReverse 0.5s;
  animation-iteration-count: 1;
}

@keyframes shakeHorizontal {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(-10px);
  }
  30% {
    transform: translateX(10px);
  }
  45% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shakeHorizontalReverse {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(10px);
  }
  30% {
    transform: translateX(-10px);
  }
  45% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

/*----------------------------------------------------------------*/

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: middle;
  background: transparent;
  scrollbar-width: thin !important;
}

body,
html {
  color: #fff;
  background: #4b79a1; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #283e51,
    #4b79a1
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #283e51,
    #4b79a1
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  text-transform: capitalize;
}

::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.space-between {
  justify-content: space-between;
}

.fade-in {
  visibility: visible;
  animation: fadeIn 1s;
}

.fade-out {
  visibility: hidden;
  animation: fadeOut 1s;
}

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5rem;
  height: 100%;
  gap: 10px;
}

.mint-overlay {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #69ca62;
  z-index: 100;
  opacity: 0;
  transition: all 0.25s;
  border-radius: 50px;
  overflow: hidden;
}

.active-overlay {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  opacity: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rays-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.play-button {
  border-radius: 25px;
  font-size: 2rem;
  font-family: Silkscreen;
  color: #fff;
  background-color: #69ca62;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  cursor: pointer;
}

.rays {
  background-image: url(assets/images/sunRays.png);
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
  width: 800px;
  height: 800px;
  animation-name: rotation;
  animation-duration: 40000ms; /* 40 seconds */
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.newIngredient {
  z-index: 11;
}

.newIngredientName {
  position: absolute;
  top: 30%;
  z-index: 12;
  font-family: Silkscreen;
  font-size: 36px;
}

.sidebarIcon {
  width: 2rem;
  height: 2rem;
}

main {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 80vw;
  height: 80vh;
  max-width: 1600px;
  border: rgba(0, 255, 191, 0.3) 3px groove;
  border-radius: 6px;
  padding: 1rem;
  box-shadow: var(--shadow-elevation-medium);
  position: relative;
  background-color: #0000001c;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.ingredientHoverText {
  font-family: Silkscreen;
  font-size: 20px;
}

.ingredientContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  width: 18rem;
  height: 100%;
}

.comboContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: space-evenly;
  flex: 1 1 auto;
  flex-wrap: wrap;
  overflow: auto;
  height: 100%;
}

.comboBoxesContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.combo {
  display: flex;
}

.comboButtonContainer {
  max-width: min-content;
}

.comboButton {
  max-width: min-content;
}

.count {
  font-size: 64px;
  font-family: "Silkscreen";
  font-weight: 700;
}

.countWrapper {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.modal {
  display: flex;
  background-color: #5539cc;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.no-modal {
  display: none;
}

.overlay-title {
  font-size: 5rem;
  font-weight: 600;
  font-family: "Procrastinating Pixie";
}

.overlay-tagline {
  font-size: 1.5rem;
  font-family: "Silkscreen";
  width: 50%;
}

.header {
  position: absolute;
  margin-top: 0px;
}

.header-title {
  font-size: 4rem;
  font-weight: 600;
  font-family: "Procrastinating Pixie";
  color: #569e9e;
}

.rotate {
  animation: rotation 8s infinite linear;
}

.reverse-anim,
.reverse-anim::before,
.reverse-anim::after {
  animation-direction: reverse;
}

.menuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  min-height: 4rem;
  border: 1px solid rgba(0, 255, 191, 0.5);
  border-radius: 6px;
  box-shadow: var(--shadow-elevation-medium-2);
  margin: 5px;
  padding: 0 20px;
  font-size: 24px;
  font-family: Silkscreen;
  cursor: pointer;
}

.mintButton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 90vw;
  max-width: 500px;
  min-height: 4rem;
  border: 1px solid rgba(0, 255, 191, 0.5);
  border-radius: 6px;
  background: linear-gradient(to top, #283e51, #4b79a1);
  margin: 5px;
  padding: 0 20px;
  font-size: 24px;
  font-family: Silkscreen;
  cursor: pointer;
  z-index: 2;
}

.mintButtonContainer {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

button:focus-visible {
  background-color: rgba(166, 15, 45, 0.55);
}

button:active {
  border: 1px solid yellow;
  border-radius: 6px;
}

.buttonContents {
  gap: 20px;
}

.detailedIcon {
  width: 2.5em !important;
  height: 2.5em !important;
}

.comboIcon {
  width: 7.5em;
  height: 7.5em;
  position: absolute;
  z-index: 10;
}

.newIngredientIcon {
  width: 10em;
  height: 10em;
  position: absolute;
}

.comboName {
  position: absolute;
  z-index: 10;
  bottom: -60px;
  font-family: Silkscreen;
  font-size: 40px;
  color: white;
  text-shadow: 2px 2px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.sidebarButton {
  text-align: center;
  width: 54px;
  height: 54px;
  padding: 10px;
  display: block;
  position: relative;
  color: #d79f47;
  font-size: 12px;
  cursor: pointer;
}

.relative {
  position: relative;
}

.errorCombo {
  animation-name: error-fx;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.ingredientName {
  font-size: 26px;
  font-family: Silkscreen;
}

.ingredientIcon {
  width: 3rem;
  height: 3rem;
}

.error {
  color: red !important;
}

.ingredient {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 5rem;
  height: 5rem;
  border: 1px solid rgba(0, 255, 191, 0.5);
  border-radius: 6px;
  box-shadow: var(--shadow-elevation-high-2);
  margin: 5px;
  cursor: pointer;
}

.ingredientDetailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  height: 4rem;
  border: 1px solid rgba(0, 255, 191, 0.5);
  border-radius: 6px;
  box-shadow: var(--shadow-elevation-medium-2);
  margin: 5px;
  padding: 0 20px;
  cursor: pointer;
}

.selectedIngredient {
  border: 3px solid #69ca62;
}

.keepsake-text {
  font-family: Silkscreen;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  text-transform: unset;
  text-decoration: none;
}

#box {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Loading spinner*/
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.load-spinner {
  position: relative;
}

.load-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid white;
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
}

.load-spinner.load-spinner--small {
  width: 19px;
  height: 19px;
  display: inline-block;
}

.load-spinner.load-spinner--medium {
  width: 32px;
  height: 32px;
}

@media (max-width: 1440px) {
  body {
    background-color: #4b79a1;
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .background {
      height: 90vh;
    }
  }
  main {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    border: none;
    background: transparent;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  #box {
    width: 100px;
    height: 100px;
  }
  .ingredientContainer {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: unset;
    max-height: 120px;
    border-top: 2px solid #69ca62;
  }
  .comboIcon {
    width: 4em;
    height: 4em;
    animation: none !important;
  }
  .menuButton {
    display: none;
  }
  .sidebar {
    display: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: min-content;
    align-self: baseline;
    margin-left: 1rem;
    margin-top: 1rem;
  }
  .ingredient {
    width: 3.5rem;
    height: 3.5rem;
    overflow: hidden;
  }
  .comboName {
    font-size: 26px;
  }
  .comboBoxesContainer {
    justify-content: center;
  }
  .comboContainer {
    height: unset;
  }
  .ingredientIcon {
    animation: none !important;
  }
  .overlay-title {
    font-size: 3rem;
  }
  .overlay-tagline {
    font-size: 1rem;
  }
  .header {
    top: 0;
    margin-top: 25px;
  }
  .header-title {
    font-size: 3rem;
  }
}

@media (max-width: 660px) {
  .header {
    display: none;
  }
  .sidebar {
    align-self: unset;
  }
}

@media (max-height: 460px) {
  .sidebar {
    display: none;
  }
  .header {
    display: none;
  }
}

.bottom {
  bottom: -25%;
}
.top {
  top: -25%;
}
.left {
  left: -25%;
}
.right {
  right: -25%;
}

.bottom-closed {
  bottom: 0;
}
.top-closed {
  top: 0;
}
.left-closed {
  left: 0;
}
.right-closed {
  right: 0;
}

.blue {
  color: rgb(1, 217, 255);
  animation-name: drip-fx;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.electric {
  color: rgb(139, 163, 166);
  animation-name: electric-fx;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(1, 0.005, 1, -0.12);
}

.red {
  color: rgb(255, 0, 0);
  animation-name: flame-fx;
  animation-duration: 4.2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.burning {
  color: rgb(255, 0, 0);
  animation-name: fire-fx;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.sunny {
  color: rgb(255, 0, 0);
  animation: sun-fx;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-direction: linear;
}

.raining {
  color: rgb(1, 217, 255);
  animation-duration: 1.5s;
  animation-name: drip-fx;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.windy {
  filter: drop-shadow(0px 0px 4px rgb(27, 85, 102));
  animation-duration: 2.3s;
  animation-name: wind-fx;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: ease-in;
}

.yellow {
  color: rgb(255, 238, 0);
  filter: drop-shadow(0px 0px 4px yellow);
}
.brown {
  color: rgb(82, 48, 9);
  filter: drop-shadow(0px 0px 4px green);
  animation-name: dirt-fx;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.trees {
  color: rgb(48, 45, 35);
  filter: drop-shadow(0px -4px 6px green);
  animation-name: dirt-fx;
  animation-duration: 15s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}

.glass {
  color: rgn(112, 128, 144);
  animation-name: glass-fx;
  animation-duration: 3.4s;
  animation-iteration-count: 10;
  animation-direction: alternate;
  animation-timing-function: ease-in;
}

.white {
  color: rgb(192, 186, 180);
  animation-duration: 2.5s;
  animation-name: breeze-fx;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.blue-gray {
  color: rgb(105, 105, 105);
  filter: drop-shadow(0px 0px 4px rgb(10, 116, 6));
}
